//
// Reboot
// --------------------------------------------------

// Import Icon Fonts (Bootstrap icons)
@import url($path-to-icon-fonts); // stylelint-disable-line import-notation

// Import Google Fonts (Manrope)
@import url($path-to-fonts); // stylelint-disable-line import-notation

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Sticky footer
html,
body {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
}
.page-wrapper {
  flex: 1 0 auto;
}
.footer {
  flex-shrink: 0;
}

// Links
a {
  transition: $link-transition;
  &:focus { outline: none; }
}

// Background transition for links
/* stylelint-disable selector-no-qualifying-type */
a[class^="bg-"]:not(.btn),
a[class*="bg-"]:not(.btn) {
  transition: background-color .25s ease;
}
/* stylelint-enable selector-no-qualifying-type */


// Images
// Responsive images (ensure images don't scale beyond their parents)
img,
figure {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
svg { max-width: 100%; }
.dark-mode-img { display: none; }

// Responsive iframes
iframe {
  width: 100%;
}

// Text Selection Color
::selection {
  background: var(--#{$prefix}user-selection-color);
}

b,
strong {
  font-weight: $font-weight-bold;
}

small { font-weight: inherit; }

// Disable button :focus outline
button:focus {
  outline: none;
}

// Images / figures
.img-thumbnail {
  background-color: $thumbnail-bg-dark;
}

// Docs container
.docs-container {
  @include media-breakpoint-up(lg) {
    padding-left: 21rem;
  }
  @include media-breakpoint-up(xxl) {
    padding-right: 20rem;
  }
}

//----------------------------------------------------------//
//	UNDERLINE
//----------------------------------------------------------//
.text-decoration-playfull {
  font-weight: $font-weight-bold;
  color: var(--#{$prefix}heading-color);
  text-shadow: 6px 6px $gray-200;
}
@each $color, $value in $theme-colors {
  .text-decoration-playfull-#{$color} {
    font-weight: $font-weight-bold;
    color: lighten($value, 15%);
    text-shadow: 3px 3px $value;
  }
}

.text-decoration-mark {
  color: inherit;
  background: url("https://s2.svgbox.net/pen-brushes.svg?ic=brush-1&color=ffff43");
  //%23 é a codificação URL para o caractere #fill="%23ff5733
  border-radius: $border-radius;
}
//não esquecer do preto e branco ,--> o nome stroke ou outline?
.text-decoration-outline-shadow {
  color: transparent;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -moz-text-stroke-width: 2px;
  -webkit-text-stroke-color: #111827;
  -moz-text-stroke-color: #111827;
  text-shadow: 6px 6px #db2777;
}
.text-decoration-outline {
  color: transparent;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -moz-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  -moz-text-stroke-color: #111827;
}
.text-decoration-sketch {
  position: relative;
  white-space: nowrap;
  &::before {
    position: absolute;
    top: .1em;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 1.25em;
    content: "";
    border-color: #f00;
    border-style: solid;
    border-width: 3px;
    border-right-color: transparent;
    border-radius: .25em;
    opacity: .5;
    transform: rotate(2deg);
  }&::after {
    position: absolute;
    top: .1em;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 1.25em;
    content: "";
    border-color: #f00;
    border-style: solid;
    border-width: 3px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-radius: .25em;
    opacity: .5;
    transform: rotate(-1deg);
  }
}

.text-decoration-circle-sketch {
  position: relative;
  white-space: nowrap;
  &::before {
    position: absolute;
    top: -.1em;
    left: -.5em;
    z-index: 2;
    width: 100%;
    height: 1.5em;
    padding: .1em .25em;
    content: "";
    border-color: #f00;
    border-style: solid;
    border-width: 3px;
    border-right-color: transparent;
    border-radius: 50%;
    opacity: .7;
    transform: rotate(2deg);
  }&::after {
    position: absolute;
    top: .1em;
    left: -.5em;
    z-index: 2;
    width: 100%;
    height: 1.5em;
    content: "";
    border-color: #f00;
    border-style: solid;
    border-width: 3px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-radius: 50%;
    opacity: .7;
    transform: rotate(-1deg);
  }
}

.text-decoration-underline {
  position: relative;
  z-index: 2;
  white-space: nowrap;
  &::after {
    position: absolute;
    bottom: 9%;
    left: -1.5%;
    z-index: -1;
    display: block;
    width: calc(100% + 2.5%);
    height: 30%;
    margin-top: 0;
    content: "";
    background: rgba(var(--#{$prefix}primary-rgb), .12);
    border-radius: 5rem;
    @include transition($btn-transition);
  }
  @each $color, $value in $colors {
    &.#{$color}:after {
      background: rgba($value, .12);
    }
  }
}
.text-decoration-underline-2 {
  position: relative;
  z-index: 2;
  white-space: nowrap;
  &::after {
    position: absolute;
    bottom: 4%;
    left: -1.5%;
    z-index: -1;
    display: block;
    width: calc(100% + 2.5%);
    height: 10%;
    margin-top: 0;
    content: "";
    background: var(--#{$prefix}primary);
    border-radius: 5rem;
    @include transition($btn-transition);
  }
  @each $color, $value in $colors {
    &.#{$color}:after {
      background: var(--#{$prefix}#{$color});
    }
  }
}
@each $gradient, $value in $theme-gradients {
  .text-decoration-underline-#{$gradient}:after {
    background: $value;
  }
}
.text-decoration-underline-3 {
  position: relative;
  z-index: 1;
  &::after {
    position: absolute;
    bottom: -.1em;
    left: 50%;
    z-index: -1;
    display: block;
    width: 110%;
    height: .3em;
    content: "";
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 100%;
    transform: translateX(-50%);
  }
  &.style-1::before,
  &.style-1::after {
    position: absolute;
    top: 52%;
    left: 50%;
    z-index: -1;
    content: "";
    border: 4px solid var(--#{$prefix}primary);
    border-radius: 80%;
    opacity: 1;
  }
  &.style-1::before {
    width: 111%;
    height: 110%;
    border-top-width: 0;
    transform: translate(-50%, -50%) rotate(-1deg);
  }
  &.style-1::after {
    width: 107%;
    height: 111%;
    border-bottom-width: 0;
    border-left-width: 0;
    transform: translate(-50%, -50%) rotate(-2deg);
  }
  @each $color, $value in $theme-colors {
    &.style-1.#{$color}::before,
    &.style-1.#{$color}::after {
      border-color: var(--#{$prefix}#{$color});
    }
  }
  &.style-2::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 208.01 8.3'%3E%3Cpath fill='{$primary}' d='M64.42,2.42Q42.78,1.46,21.19,0c-2.8-.19-4.09.89-3.87,2L3.92,1.87c-5.13-.05-5.28,3.87-.12,3.92l60.49.55c46.63,2.08,93.34,2.51,139.81,1.27,5-.13,5.39-3.87.13-3.92Z'/%3E%3C/svg%3E");
  }
  @each $color, $value in $theme-colors {
    &.style-2.#{$color}::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 208.01 8.3'%3E%3Cpath fill='{$value}' d='M64.42,2.42Q42.78,1.46,21.19,0c-2.8-.19-4.09.89-3.87,2L3.92,1.87c-5.13-.05-5.28,3.87-.12,3.92l60.49.55c46.63,2.08,93.34,2.51,139.81,1.27,5-.13,5.39-3.87.13-3.92Z'/%3E%3C/svg%3E");
    }
  }
  &.style-3::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 205 9.37'%3E%3Cpath fill='{$primary}' d='M202.47,9.37A1191.26,1191.26,0,0,0,1.79,7.48,1.67,1.67,0,0,1,0,5.92H0A1.76,1.76,0,0,1,1.63,4.21c67-5.71,133.83-5.43,200.8-.27A2.75,2.75,0,0,1,205,6.88h0A2.6,2.6,0,0,1,202.47,9.37Z'/%3E%3C/svg%3E");
  }
  @each $color, $value in $theme-colors {
    &.style-3.#{$color}::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 205 9.37'%3E%3Cpath fill='{$value}' d='M202.47,9.37A1191.26,1191.26,0,0,0,1.79,7.48,1.67,1.67,0,0,1,0,5.92H0A1.76,1.76,0,0,1,1.63,4.21c67-5.71,133.83-5.43,200.8-.27A2.75,2.75,0,0,1,205,6.88h0A2.6,2.6,0,0,1,202.47,9.37Z'/%3E%3C/svg%3E");
    }
  }
}
@media (max-width: 1399.98px) {
  .text-decoration-underline-3.style-1::before,
  .text-decoration-underline-3.style-1::after {
    display: none;
  }
}

