// Estilo para o container
.mockup-preview {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  background: repeating-linear-gradient(0deg, transparent 0 25px, #f0f0f0 25px 26px), repeating-linear-gradient(90deg, transparent 0 25px, #f0f0f0 25px 26px);
}

// Estilo para todas as imagens dentro do container
.mockup-preview img {
  position: absolute;
  top: auto;
  left: auto;
}

// Estilo apenas para a primeira imagem dentro do container
.mockup-preview > img:first-child {
  position: relative;
}
